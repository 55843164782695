import * as React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Banner from 'components/Banner';
import Loader from 'components/Loader';
import Contact from 'components/Contact';
import About from 'components/About';
import Footer from 'components/Layout/Footer';
import BasicInfo from 'components/BasicInfo';
import Products from 'components/Products';

const IndexPage: React.FunctionComponent = () => {
  return (
    <Layout displayFooter={false}>
      <SEO title="StreamEYE" />
      <Loader />
      <Banner />
      {/* <BasicInfo />
      <About />
      <Products />
      <Contact />
      <Footer /> */}
    </Layout>
  );
};

export default IndexPage;
